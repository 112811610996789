import * as React from 'react';
import styled from 'styled-components';
import {Media} from "../constants";

export type SectionImageProps = {
    src: string;
    variant: 'hero' | 'left' | 'right'
    className?: string;
}
export function SectionImage({src, variant, className}: SectionImageProps) {
    return <Image src={src} className={className} variant={variant}/>;
}
const Image = styled.img<SectionImageProps>`
  width: 60vw;
  margin: 1rem auto;
  
  
  @media (${Media.tablet}) {
    width: 30vw;
    display: block;
    margin-right: 5vw;
  }
  @media (${Media.desktop}) {
    width: 35rem;
    ${({variant}) => {
        switch (variant) {
            case "left":
                return `margin-right: 6rem`;
            case "right":
                return `margin-left: 6rem`;
        }
    }}
  }
`;
